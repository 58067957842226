.forgot-password{
  .forgot-password-heading {
    display: flex;
    margin: auto;
    padding: 20px;
    justify-content: center;
    background-color: #96c3ec;

    img {
      margin-right: 20px;
    }
  }
  .enter-email-box {
    display: flex;
    flex-direction: column;
    border: 1px solid #c3cfd9;
    width: 30vw;
    height: 50vh;
    margin: 10vh auto;
    h3{
      margin: 50px auto;
    }
    @media (min-width: 1500px) {
      display: flex;
      flex-direction: column;
      border: 1px solid #c3cfd9;
      width: 30vw;
      height: 50vh;
      margin: 10vh auto;
      h3{
        margin: 50px auto;
      }
    }
  }

  .enter-email-input{
    margin-left: 10%;
    input{
      width: 90%;
      height: 50px;
      margin-bottom: 30px;
    }
    label{
      margin-bottom: 10px;
    }
    #email-id{
      background-image: url('../../assets/lock_bold.svg');
      background-size:contain;
      background-repeat: no-repeat;
      border: 1px solid #c5ced5;
    }
    #email-id:focus {
      background-image: none;
      text-indent: 0;
      font-size: 18px;
    }
    .contains-input {
      background-image: none!important;
      text-indent: 0;
      font-size: 18px;
    }
  }

  .reset-button {
    background-color: #2c88d9;
    width: 50%;
    padding: 10px;
    display: flex;
    margin: 0 auto;
    border-radius: 5px;
    color: white;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    align-items: center;
  }
}