.topics{
  .button-image{
    width: 20px;
    height: 20px;
  }
  //.prompt-text{
  //  white-space: nowrap;
  //  text-overflow: ellipsis;
  //  overflow: hidden;
  //  max-width: 200px;
  //}
  .link{
    font-size: 18px;
    font-weight: 500;
  }
  .link:visited{
    color: #388edb;
  }
  .heading-10{
    margin-bottom: 10px;
  }
}