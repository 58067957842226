$border-color: #b4c2c7;

.edit-360 {
  width: 100%;
  padding: 25px 25px;
  background: #f4f7f8;
  border: 2px solid $border-color;
  margin-top: 2em;

  .location-container {
    display: flex;
    justify-content: space-between;

    .coordinates-container {

      div {
        margin-right: 15px;
      }
    }

    .location-image-container {
      display: flex;

      img {
        width: 50%;
        margin-right: 15px;
      }
    }

    p {
      font-size: 1.15rem;
      padding: .2em .3em;
      width: 95%;
    }
  }

  .prompt-container {
    background: white;
    border: 2px solid $border-color;
    padding: 25px 25px;
    margin-top: 5%;

    form > h3:first-of-type {
      margin-top: 0;
    }

    .update-button {
      color: white;
      background: #2d815b;
      font-weight: 600;
      padding: 0.5em 30px;
      cursor: pointer;
      font-size: 1.15rem;
      display: block;
      border-radius: 5px;
      margin-top: 5%;
    }

    textarea {
      resize: none!important;
      width: 100%;
      font-size: 1.15rem;
      padding: .4em .5em;
      border: 1px solid $border-color;
    }

    textarea:focus {
      outline: 2px solid #00abe2;
    }

    img {
      width: 50%;
    }

    .change-btn {
      background: none;
      margin-left: 10%;
      height: 20%;

      img {
        width: 100%;
      }
    }
  }
}