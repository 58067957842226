.form-container {
  width: 35%;
  margin: 5% auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 10px;
  border: 1px solid #c3cfd9;

  h3{
    margin: 0 0 2em;
  }

  input {
    display: flex;
    flex-direction: column;
    padding: .25em;
    margin-top: 10px;
    border: 1px solid #c3cfd9;
  }

  input:focus {
    outline: 2px solid #96c3ec;
  }

  label {
    text-align: center;
    font-size: 1.15rem;
    cursor: text;
  }

  label:first-of-type {
    margin-bottom: 5%;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  button {
    margin-top: calc(15px + 5%);
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    background: #2c88d9;
    padding: .5em .25em;
    border-radius: 5px;
  }

  span {
    color: #ed0202;
    font-size: .75rem;
  }

  .mismatch {
    border: 1px solid #ed0202;
    background-color: rgb(237, 2, 2, 0.2);
  }

  p {
    font-size: 1.15rem;
  }
}

