$border-color: #b7bcc2;

.prompt-review{
  width: 100%;
  margin-top: 2em;
  display: flex;
  justify-content: space-evenly;
}

.edit-area {
  width: 65%;
  display: grid;
  grid-template-columns: 35% 65%;

  .content-cell{
    display: flex;
    width: 100%;

    p {
      font-size: 1.15rem;
      margin: auto 0;
    }
  }

  input, select, textarea {
    width: 100%;
    border: none;
    font-size: 1.15rem;
    padding: 5px 0;
  }

  textarea {
    padding: .5rem;
    resize: vertical;
    max-height:  18rem;
    border: 1px solid $border-color
  }

  textarea:focus {
    outline: 2px solid #00abe2;
  }

  select {
    background: none;
    cursor: pointer;
  }

  select:focus {
    outline: none;
  }
}

.button-area {
  display: flex;
  flex-direction: column;
  width: 35%;
  padding: 0 50px;
  align-items: center;

  .delete-btn {
    margin-top: 15%;
    margin-bottom: 15px;
  }

  a {
    font-size: 1.02rem;
    margin-bottom: 7%;
    color: #0276b2
  }

  p {
    font-size: 1.02rem;
    width: 250px;
    text-align: center;
  }
}
