$default-border: 1px solid #b7bcc2;
$focus-color: #00abe2;

.prompt-create {
  margin-top: 2em;
  width: 85%;

  form {
    background: #f4f7f8;
    border: $default-border;
    padding: 20px;

    h4 {
      margin-top: 5%;
    }
  }

  textarea {
    width: 100%;
    resize: none;
    padding: 5px 10px;
    font-size: 1.15rem;
    min-height: calc(2.5rem + 10px);
    border: $default-border;
  }

  textarea:focus {
    outline: 1px solid $focus-color;
  }
}

.select-container {
  display: flex;
  justify-content: space-between;
  width: 35%;

  label {
    display: flex;
    flex-direction: column;
    font-size: 1.15rem;
  }

  select {
    background: white;
    border: $default-border;
    margin-top: 15px;
    padding: 0 50px 0 0;
    font-weight: normal;
  }

  select:focus {
    outline: $focus-color;
  }
}

.file-input-label {
  font-size: 1.15rem;
  margin: 5% auto 0 0;
}

#main-media-input, #thumbnail-input {
  font-size: 1rem;
  font-weight: 500;
  margin: 10px 0 5px 0;
}

.submit {
  color: white;
  background: #2d815b;
  width: 200px;
  font-weight: 600;
  text-align: center;
  padding: 0.5em 0.5em;
  cursor: pointer;
  font-size: 1.15rem;
  margin: 35px auto 10px;
  display: block;
  border-radius: 5px;
}

.upload {
  background: white;
  font-size: 1.05rem;
  font-weight: 500;
  border: $default-border;
  border-radius: 5px;
  padding: 5px 15px;
}