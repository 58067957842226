.stories-table {
  table-layout: fixed;
  width: 100%;
  margin-top: 3%;

  th {
    font-size: 1.2em;
    padding: 1em 0 1em 0.5em;
    text-align: left;
    background: #b7bcc2;
  }

  td {
    padding: 5px 5px;
    font-size: 1.15em;
  }

  td:nth-of-type(6) {
    text-align: center;
  }

  tr {
    background: #f4f7f8;
  }

  .unapproved {
    background: #e4ebee;
  }

  td, th {
    border: 1px solid black;
  }

  img {
    transform: scale(.5);
  }

  .edit-link {
    color: #0276b2
  }

  .review-link {
    color: red;
  }
}

.filter-container {
  padding: 0.75em 20% 0.75em 0.75em;
  background: #f4f7f8;
  border: 2px solid #b4c2c7;
  display: flex;
  justify-content: space-between;
  margin-top: 2%;

  button, select {
    font-size: 1.5em;
    background: none;
    font-weight: 500;
    padding: 10px 0;
  }

  .selected {
    color: #0276b2;
  }

  .underlined {
    border-bottom: 5px solid #0276b2;
  }

  select {
    border: none;
    background: none;
  }
}

.prompt-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}