.edit-topic {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-button-container {
  margin-top: 1.5rem;

  button {
    background: none;
    font-size: 1.2rem;
    margin: 0 1rem;
    padding-bottom: 10px;
  }
}

.selected {
  color: #ec1f3a;
  border-bottom: .2rem solid #ec1f3a;
}

.header-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  a {
    margin: 20px 0 10px 0;
    font-size: 1.25rem;
  }

  .spacing-box {
    visibility: hidden;
    font-size: 1.25rem;
    margin: 0;
  }
}