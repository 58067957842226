$border-color: #b7bcc2;

.story-edit {
  display: grid;
  grid-template-columns: 35% 65%;

  h4, p {
    margin: 0;
  }

  input, select, .data-tag {
    width: 100%;
    border: none;
    font-size: 1.15rem;
    padding: 5px 0;
  }

  input {
    padding: .5em;
    border: 1px solid $border-color;
    border-radius: 5px;
  }

  select, .data-tag {
    background: none;
  }

  select:focus {
    outline: none;
  }

  select {
    cursor: pointer;
  }
}

.media-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .attached-img, video{
    max-width: calc(100% - 79.65px);
  }

  .delete-media-btn {
    background: none;
    cursor: pointer;
    padding: 0;

    img {
      transform: scale(.8);
    }
  }
}

.header-cell {
  border-bottom: 1px solid $border-color;
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  align-content: center;
  background: #e4ebee;
}

.content-cell {
  border-bottom: 1px solid $border-color;
  border-right: 1px solid $border-color;
  background: #f4f7f8;
}

.header-cell, .content-cell {
  padding: 3% 2%;
}

.quill {
  .ql-toolbar, .ql-editor {
    background: white;
  }
}

.initial-header-cell {
  grid-column-start: 1;
  grid-column-end: 1;
  border-top: 1px solid $border-color;
}

.initial-content-cell {
  grid-column-start: 2;
  grid-column-end: 2;
  border-top: 1px solid $border-color;
}

.text-edit-area {
  width: 100%;
}