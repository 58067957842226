.dashboard {
  .div-block-5 {
    border: #5d6c7b solid 1px;
  }

  .heading {
    font-size: 28px;
    margin: auto;
    text-align: center;
  }
  .section-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .page-header {
    .greeting-message {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .pink-bar {
        background-color: pink;
        width: 20%;
        height: 15px;
      }
    }
    h1 {
      font-size: 50px;
    }
  }
  .graphGrid{
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .siteVisitOverview{
      width: 45vw;
      margin:0;
      h3{
        margin: 20px 20px 20px 40px;
      }
      .lineGraph{
        width: 100%;
        height: 100%;
      }
    }
    .typeSubmissions{
      width: 30vw;
      margin: 0;
      .typeSubmissionsPieChart{
        width: 30vw;
        height:85%;
        padding-right: 35px;
      }
    }
    .topicSubmissions{
      width: 45vw;
      margin: 0;
    }
    .cardUsage {
      width: 30vw;
      margin: 0;
      .cardUsagePieChart {
        width: 30vw;
        height: 85%;
        padding-right: 35px;
      }
    }
    .recentStories{
      .recentStoriesBox{
        padding: 0 20px 10px 0;
        width: 77.5vw;
        .storyBox{
          text-decoration: none;
          max-height: 30vh;
          overflow: hidden;
          text-overflow: ellipsis;
          .innerMessageDiv{
            color: black;
            text-transform: capitalize;
            word-break: break-all;
          }
          h4{
            color: black;
            text-transform: capitalize;
            word-break: break-all;
          }
          background-color: rgba(94, 202, 230, 0.19);
          border-radius: 10px;
          padding: 5px 0 5px 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
}