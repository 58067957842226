.topic-prompts {
  width: 75%;
}

.prompt-table {
  width: 100%;
  table-layout: auto;
  margin-top: 2em;

  .text-column {
    width: 65%;
  }
  .media-column {
    width: 20%;
  }
  .edit-column {
    width:15%;
  }

  td,th {
    padding: .75rem;
  }
  td {
    font-size: 1rem;
    background: #e4ebee;
  }
  th {
    font-size: 1.25rem;
    background: #b7bcc2;
  }

  a {
    color: #0276b2
  }
}

table, td, th {
  border-collapse: collapse;
  border: 1px solid black;
}
