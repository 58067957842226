.users {
  display: flex;
  padding: 10vh 2vw;

  table {
    table-layout: auto;
    width: 80%;

    .email-column {
      width: 50%
    }

    .date-column {
      width: 50%;
    }

    th, td {
      padding: 0.5em;
    }

    th {
      background: #b7bcc2;
      font-size: 1.5rem;
    }

    td {
      background: #e4ebee;
      line-break: anywhere;
    }

    td:nth-of-type(2) {
      text-align: center;
    }

    tr {
      font-size: 1.2rem;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5%;

    .user-edit {
      display: grid;
      grid-template-columns: 35% 65%;
    }

    .header-cell, .content-cell {
      padding: 1em 1em;
    }

    .content-cell {
      display: flex;
      flex-direction: column;
    }

    input {
      font-size: 1.2rem;
      padding: .25em;
      border: 1px solid #b7bcc2;
      border-radius: 5px;
    }

    button {
      height: 50px;
      margin-top: 15px;
      background: #2c88d9;
      color: white;
      font-size: 1.3rem;
      font-weight: 600;
      border-radius: 5px;
      padding: .5em .75em;
    }

    p {
      font-size: .75rem;
      margin: 0;
    }
  }
}